import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PageTitle from "../components/PageTitle"
import PostList from "../components/PostList"
import Seo from "../components/seo"

class author_post_list_template extends Component {
  toCapitalize(authorName) {
    let splitStr = authorName.toLowerCase().split("-")
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  }
  render() {
    return (
      <Layout>
        <Seo
          title={`${this.toCapitalize(
            this.props.pageContext.author
          )} Author at CodeParadox`}
          description={`${this.toCapitalize(
            this.props.pageContext.author
          )} Author at CodeParadox`}
          keywords={this.props.data.allMarkdownRemark.edges.map(post =>
            post.node.frontmatter.categories
              .concat(post.node.frontmatter.tags)
              .join(",")
          )}
        />
        <PageTitle
          pageTitle={this.props.pageContext.author.replace(/^\w/, c =>
            c.toUpperCase()
          )}
        />
        <div className="post-background">
          <div className="post-container">
            {this.props.data.allMarkdownRemark.edges.map(post => (
              <PostList post={post} key={post.node.id} />
            ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export const authorPostListQuery = graphql`
  query authorPostListQuery($author: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { author: { in: [$author] } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date
            author
            categories
            tags
            thumbnail {
              childImageSharp {
                fixed(width: 295, height: 200) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
            }
          }
          excerpt(pruneLength: 235)
          html
        }
      }
    }
  }
`

export default author_post_list_template
